import styled from "@emotion/styled";
import mq from "@styles/mq";
import { Button } from "semantic-ui-react";

export const GiftToggleButton = styled(Button)`  

`
export const ButtonToggler = styled.div`
    div {
        background: #fff !important;
        color: #000 !important;
        padding: 16px 40px !important;
        
    }
    .left.attached.button{
        border-radius: 10px 0px 0px 10px !important;
        box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12) !important;
    }
    .left.attached.button.active{
        border-radius: 10px !important;
    }
    .right.attached.button {
        border-radius: 0px 10px 10px 0px !important;
        box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.12) !important;
    }
    .right.attached.button.active{
        border-radius: 10px !important;
    }
    .active {
        background: #000 !important;
        color: #fff !important;
    }

`

export const GiftingStepsTitle = styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 30px;
`
export const GiftingStepItemTitle = styled.div`
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 10px;
`
export const GiftingOptionsTitle = styled.div`
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    margin-bottom: 16px;
`
export const GiftingExploreDesignsTitle = styled.div`
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    margin-bottom: 56px;
`
export const CardWraper = styled.div`
    margin-top: 10px;
    text-align: left;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
`