import styled from "@emotion/styled";
import mq from '@styles/mq'

export const GiftVoucherFrame = styled.iframe`  
    box-shadow: 0px 6px 16px #0000001F;
    border-radius: 12px;
    border: none;
    width: 100%;
    background-color: #FFFFFF;
    ${mq({
    height: ['180vw', '1260px', '1240px'],
    padding: ['5px', '40px', '40px']
})}
`