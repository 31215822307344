import { GiftingExploreDesignsProps } from "@models/Gifting/Gifting.models"
import {  TransactionTypesSetting, VehicleTypesSetting } from "@models/ProductModels";
import React from "react"
import ReactSlick from "react-slick";
import { Grid } from "semantic-ui-react"
import { GiftingExploreDesignsTitle } from "./GiftPage.styles";
import GiftPlateDesignStyleItemContainer from "./GiftPlateDesignStyleItemContainer";
import { NextArrow, PrevArrow } from "@components/CustomerReviewCardSlider/CustomerReviewCardSlider";

const GiftingExploreDesignsContainer = ({ title, giftingExploreDesigns, apiUrl} : GiftingExploreDesignsProps) => {
    const currentPlateDesign = giftingExploreDesigns[0].products.buildDefaultPlateDesignProps();
    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        slidesToShow: 4,
        lazyLoad: 'progressive',
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        centerMode: true,
        responsive: [
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 2,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 650,
            settings: {
              centerMode: true,
              centerPadding: '30px',
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          }
        ]
      };
    
    return (
      <Grid columns={8}>               
          <Grid.Row columns={16} centered>
              <GiftingExploreDesignsTitle>{title}</GiftingExploreDesignsTitle>
              <ReactSlick {...settings}>
              {
                giftingExploreDesigns.map((item: any, index: number) => {
                    return (
                        <GiftPlateDesignStyleItemContainer apiUrl={apiUrl}
                            key={index}
                            vehicleType={VehicleTypesSetting.Car}
                            currentPlateDesign={currentPlateDesign}
                            formattedCombination="PLATES"
                            data={item}
                            transactionType={TransactionTypesSetting.New}
                            onSelectCard=""
                            productId=""
                        />)
                })
            }
            </ReactSlick>
              
          </Grid.Row>
      </Grid>
    )
  }
  
  export default GiftingExploreDesignsContainer