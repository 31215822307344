import { KpButton } from "@elements/index"
import { GiftingStepItemProps, GiftingStepsProps } from "@models/Gifting/Gifting.models"
import theme from "@styles/theme"
import React from "react"
import { Grid } from "semantic-ui-react"
import { GiftingStepItemTitle, GiftingStepsTitle } from "./GiftPage.styles"

const GiftingStepsContainer = ({ title, steps} : GiftingStepsProps) => {

    return (
      <Grid stackable textAlign='center'>               
          <Grid.Row columns={16} centered>
              <GiftingStepsTitle>{title}</GiftingStepsTitle>
              {
                  steps.map((step: GiftingStepItemProps, index) => {
                      return (
                        <Grid.Column key={index} width={5} textAlign="center">
                            <img height={51} src={step.icon.url} alt={step.icon.description} />
                            <GiftingStepItemTitle>
                                {step.title}
                            </GiftingStepItemTitle>
                            <div style={{marginBottom: "32px"}}>
                                {step.description}
                            </div>
                        </Grid.Column>
                      )
                  })
              }
              <Grid.Column width="16" textAlign='center'>
                <KpButton id='create-button' buttonType='primary' link='/create/' color={theme.brand.colors.blue}>Create a Plate</KpButton>
              </Grid.Column>
          </Grid.Row>
      </Grid>
    )
  }
  
  export default GiftingStepsContainer