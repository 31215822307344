import React from 'react'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Grid } from 'semantic-ui-react'
import { H3, Paragraph1 } from '@styles/Global.styles'
import { GiftVoucherFrame } from './GiftVoucher.styles'


interface GiftVoucherProps {
    RoboVoucherId?: string
}

const GiftVoucher = (props: GiftVoucherProps) => {
    return (
        <Grid stackable textAlign='center'>
            
            {
                props.RoboVoucherId &&
                <Grid.Row>
                    <Grid.Column>
                        <script type="text/javascript" src={`https://www.robovoucher.com/buy/embed/${props.RoboVoucherId}/`}></script>
                        <GiftVoucherFrame src={`https://www.robovoucher.com/buy/list/${props.RoboVoucherId}/`} name="RoboVoucherFrame" id="RoboVoucherFrame"></GiftVoucherFrame>
                    </Grid.Column>
                </Grid.Row>
            }
        </Grid>
    );
}

export default GiftVoucher